import React from "react"

// Components
import Layout from "../../../components/layout"
import Band from "../../../components/band"
import SEO from "../../../components/seo"
import PhoneValidationTopNav from "../../../components/top-nav/phone-validation-top-nav"

// CSS
import grid from "../../../shared/bootstrap-grid.module.css"

// Files
import ArchitecturalOverviewPePdfSrc from "../../../files/Phone Validation Technical Overview PE.pdf"
import ArchitecturalOverviewEePdfSrc from "../../../files/Phone Validation Technical Overview EE.pdf"

const PhoneValidationArchitecturalOverviewPage = () => (
  <Layout>
    <SEO title="Phone Validation" keywords={[`tigerface`, `systems`, `salesforce`, `products`, `phone validation`]} />

    <PhoneValidationTopNav />

    <Band>
      <h1>Architectural Overview</h1>

      The architectural overviews are available in PDF version to make distribution easier.
      <div className={grid.mt2}>
        <a href={ArchitecturalOverviewPePdfSrc} target="_blank" rel="noopener noreferrer">View Professional Edition PDF</a><br/>
        <a href={ArchitecturalOverviewEePdfSrc} target="_blank" rel="noopener noreferrer">View Enterprise Edition PDF</a>
      </div>
    </Band>
  </Layout>
);

export default PhoneValidationArchitecturalOverviewPage;